body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#customScroll::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
#customScroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
#customScroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}

/* Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .redBtn {
    @apply bg-red-500 hover:bg-red-400 active:bg-red-600 focus:ring-red-300;
  }

  .defaultBtn {
    @apply bg-violet-500 hover:bg-violet-400 active:bg-violet-600 focus:ring-violet-300;
  }
}

